<template>
  <div class="base-delete-patient-button">
    <button
      class="delete-patient-button"
      type="button"
      @click="handleClick"
      data-test="del-patient-btn"
    >
      <span class="delete-icon">
        <span class="cross-mark"></span>
      </span>
      <span class="delete-patient-button-text">{{ $t('common.delete') }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseDeletePatientButton',

  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-delete-patient-button {
  > .delete-patient-button {
    width: 80px;
    height: 45px;
    border-radius: 6px;
    border: solid 2px #{$plus_ce_gray};
    background-color: #{$plus_white};
    padding: 0;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
      background-color: #{$plus_ee_gray};
    }
    &:active {
      margin: 2px auto 0 auto;
      height: 43px;
    }
    > .delete-icon {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 18.6px;
      height: 18.6px;
      background-color: #{$plus_de_red};
      border-radius: 50%;
      > .cross-mark {
        &::after,
        &::before {
          display: block;
          content: '';
          position: absolute;
          top: 4.3px;
          left: 8.4px;
          width: 2px;
          height: 10px;
          border-radius: 2px;
          background: #{$plus_white};
        }
        &::after {
          transform: rotate(-50deg);
        }
        &::before {
          transform: rotate(50deg);
        }
      }
    }
    > .delete-patient-button-text {
      height: 22px;
      font-size: 15px;
      font-weight: bold;
      line-height: 1.47;
      text-align: left;
      color: #{$plus_3e_black};
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
    }
  }
}
</style>
