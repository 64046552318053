<template>
  <div class="base-button-plus">
    <button
      class="add-button"
      type="button"
      @click="handleClick"
      :disabled="disabledFlg"
    >
      <span class="plus-icon">
        <span
          class="plus-icon-line vertical"
          :class="{ disabled: disabledFlg }"
        ></span>
        <span
          class="plus-icon-line horizontal"
          :class="{ disabled: disabledFlg }"
        ></span>
      </span>
      <span class="button-text" :class="{ disabled: disabledFlg }">{{
        text
      }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseButtonPlus',

  props: {
    disabledFlg: { type: Boolean, default: false },
    text: { type: String, default: '' }
  },

  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-button-plus {
  display: inline-block;
  > .add-button {
    padding: 0 12px;
    height: 45px;
    border-radius: 6px;
    border: solid 2px #{$plus_ce_gray};
    background-color: #{$plus_white};
    cursor: pointer;
    &:hover {
      opacity: 0.8;
      background-color: #{$plus_ee_gray};
    }
    &:active {
      margin: 2px auto 0 auto;
      height: 43px;
    }
    &:disabled {
      background-color: #{$plus_ce_gray};
      opacity: 1;
      cursor: default;
      &:active {
        margin: 0px;
        height: 45px;
      }
    }
    > .plus-icon {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 18px;
      height: 18px;
      > .plus-icon-line {
        position: absolute;
        background-color: #{$plus_orange};
        border-radius: 1px;
        &.disabled {
          background-color: #{$plus_7c_gray};
        }
        &.vertical {
          top: 0px;
          left: 8px;
          width: 2px;
          height: 18px;
        }
        &.horizontal {
          top: 8px;
          left: 0px;
          width: 18px;
          height: 2px;
        }
      }
    }
    > .button-text {
      height: 22px;
      font-size: 15px;
      font-weight: bold;
      line-height: 1.47;
      text-align: left;
      color: #{$plus_3e_black};
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
      &.disabled {
        color: #{$plus_7c_gray};
      }
    }
  }
}
</style>
