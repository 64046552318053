<template>
  <div class="base-patient-breed-text-box">
    <input
      class="patient-breed-text-box"
      type="text"
      maxlength="50"
      :value="value"
      :placeholder="placeholder"
      @input="inputText"
      @blur="$emit('blur')"
    />
  </div>
</template>

<script>
export default {
  name: 'BasePatientBreedTextBox',

  props: {
    value: { type: String },
    placeholder: { type: String }
  },

  methods: {
    inputText(e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.base-patient-breed-text-box {
  > .patient-breed-text-box {
    width: 110px;
    height: 45px;
    border-radius: 5px;
    border: solid 2px #{$plus_ce_gray};
    background-color: #{$plus_white};
    padding: 2px 13px;
    @include font-size(18);
    text-align: left;
    box-sizing: border-box;
    &::placeholder {
      color: #{$plus_be_gray};
    }
    &::-ms-clear {
      display: none;
    }
  }
}
@media (max-width: $global-media-width) {
  .base-patient-breed-text-box {
    > .patient-breed-text-box {
      width: 161px;
    }
  }
}
@media (max-width: 480px) {
  .base-patient-breed-text-box {
    > .patient-breed-text-box {
      width: 105px;
    }
  }
}
</style>
