<template>
  <div class="patient-data-input-form">
    <validation-provider
      rules="requiredRule|notSurrogatePair"
      v-slot="{ errors }"
      class="provider"
    >
      <base-patient-name-text-box
        :placeholder="$t('common.placeholderPetName')"
        v-model="patient.name"
        class="patient-name-text-box"
      />
      <span class="error" data-test="name-err">{{ $t(errors[0]) }}</span>
    </validation-provider>
    <div class="type-breed-area">
      <label class="type-label">{{ $t('common.labelAnimalType') }}</label>
      <validation-provider
        rules="requiredRule"
        v-slot="{ errors }"
        class="provider"
      >
        <base-select-box
          :select-data="speciesList"
          v-model="patient.speciesId"
        />
        <div class="error select" data-test="species-err">
          {{ $t(errors[0]) }}
        </div>
      </validation-provider>
      <label class="breed-label">{{ $t('common.labelBreed') }}</label>
      <validation-provider
        rules="notSurrogatePair"
        v-slot="{ errors }"
        class="provider"
      >
        <base-patient-breed-text-box
          :placeholder="$t('common.placeholderBreed')"
          v-model="patient.breed"
          class="patient-breed-text-box"
        />
        <div class="error" data-test="breed-err">{{ $t(errors[0]) }}</div>
      </validation-provider>
      <template v-if="scenario === 'first_reservation'">
        <base-delete-patient-button @click="delPatient" v-if="index !== 0" />
      </template>
      <template v-else-if="scenario === 'change_owner_patients'">
        <base-delete-patient-button
          @click="delPatient"
          v-if="
            !isIncludesPatientIdInReservations &&
              !existsMedicalRecordInPatientFlg
          "
        />
      </template>
      <template v-else>
        <base-delete-patient-button @click="delPatient" />
      </template>
    </div>
    <div class="type-breed-area-under-960-width">
      <label class="patient-name-label-under-960-width" v-if="index !== 0"
        >{{ $t('common.labelPetName') }} <base-required-mark
      /></label>
      <validation-provider
        rules="requiredRule|notSurrogatePair"
        v-slot="{ errors }"
        class="provider"
      >
        <base-patient-name-text-box
          :placeholder="$t('common.placeholderPetName')"
          v-model="patient.name"
          class="patient-name-text-box"
        />
        <div class="error" data-test="name-err">{{ $t(errors[0]) }}</div>
      </validation-provider>
      <label class="type-breed-label"
        >{{ $t('common.labelAnimalType') }}・{{ $t('common.labelBreed') }}
        <base-required-mark
          v-if="
            scenario === 'change_reservation' ||
              scenario === 'first_reservation' ||
              scenario === 'change_owner_patients'
          "
      /></label>
      <div class="type-breed-input-area">
        <div class="type-breed-input-box">
          <validation-provider
            rules="requiredRule"
            v-slot="{ errors }"
            class="provider"
          >
            <base-select-box
              :select-data="speciesList"
              v-model="patient.speciesId"
              class="patient-type-select-box"
            />
            <div class="error select" data-test="species-err">
              {{ $t(errors[0]) }}
            </div>
          </validation-provider>
          <validation-provider
            rules="notSurrogatePair"
            v-slot="{ errors }"
            class="provider"
          >
            <base-patient-breed-text-box
              :placeholder="$t('common.placeholderBreed')"
              v-model="patient.breed"
              class="patient-breed-text-box"
            />
            <div class="error" data-test="breed-err">{{ $t(errors[0]) }}</div>
          </validation-provider>
        </div>
        <div
          v-if="scenario === 'first_reservation'"
          class="delete-patient-button"
        >
          <base-delete-patient-button @click="delPatient" v-if="index !== 0" />
        </div>
        <div
          v-else-if="scenario === 'change_owner_patients'"
          class="delete-patient-button"
        >
          <base-delete-patient-button
            @click="delPatient"
            v-if="
              !isIncludesPatientIdInReservations &&
                !existsMedicalRecordInPatientFlg
            "
          />
        </div>
        <div v-else class="delete-patient-button">
          <base-delete-patient-button @click="delPatient" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasePatientNameTextBox from '@/components/parts/atoms/BasePatientNameTextBox.vue'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox.vue'
import BasePatientBreedTextBox from '@/components/parts/atoms/BasePatientBreedTextBox.vue'
import BaseRequiredMark from '@/components/parts/atoms/BaseRequiredMark.vue'
import BaseDeletePatientButton from '@/components/parts/atoms/BaseDeletePatientButton.vue'
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'

export default {
  name: 'PatientDataInputForm',

  components: {
    BasePatientNameTextBox,
    BaseSelectBox,
    BasePatientBreedTextBox,
    BaseRequiredMark,
    BaseDeletePatientButton,
    ValidationProvider
  },

  props: {
    patient: { type: Object },
    index: { type: Number },
    isIncludesPatientIdInReservations: {
      type: Boolean,
      default: false
    },
    existsMedicalRecordInfo: { type: Array }
  },

  computed: {
    species() {
      return this.$store.getters['species/getData']
    },
    deletedSpecies() {
      return this.$store.getters['species/getDataIncludeDelData'].filter(
        v => v.delFlg === 1
      )
    },
    speciesList() {
      const deletedSpeciesIds = this.deletedSpecies.map(v => v.id)
      const speciesList = deletedSpeciesIds.includes(
        this.patient.initialSpeciesId
      )
        ? this.species.concat(
            this.deletedSpecies.filter(
              v => v.id === this.patient.initialSpeciesId
            )
          )
        : this.species
      if (this.displayLanguage === 'en') {
        return speciesList.map(v => {
          return { ...v, name: v.nameEnglish || v.name }
        })
      }
      return speciesList
    },
    scenario() {
      return this.$store.getters['auth/scenario']
    },
    existsMedicalRecordInPatientFlg() {
      if (this.existsMedicalRecordInfo?.length > 0) {
        const info = this.existsMedicalRecordInfo.find(
          v => v.patientId === this.patient.id
        )
        return info ? info.existsMedicalRecordFlg : false
      } else {
        return false
      }
    },
    displayLanguage() {
      return this.$i18n.locale
    }
  },

  methods: {
    delPatient() {
      this.$emit('del-patient', { value: this.patient, index: this.index })
    }
  }
}
</script>

<style lang="scss" scoped>
.patient-data-input-form {
  display: flex;
  margin-bottom: 20px;
  font-weight: bold;
  word-break: normal;
  @include validate-message();
  > .type-breed-area {
    display: flex;
    @include validate-message();
    > .type-label {
      font-size: 17px;
      margin: 9px 15px 0 25px;
      white-space: nowrap;
    }
    > .breed-label {
      margin: 9px 14px 0 29px;
    }
    > .provider {
      > .patient-breed-text-box {
        margin-right: 27px;
      }
    }
  }
  > .type-breed-area-under-960-width {
    display: none;
  }
}
@media (max-width: $global-media-width) {
  .patient-data-input-form {
    display: block;
    margin-bottom: 30px;
    > .provider {
      > .patient-name-text-box,
      .error {
        display: none;
      }
    }
    > .type-breed-area {
      display: none;
    }
    > .type-breed-area-under-960-width {
      display: block;
      @include validate-message();
      > .patient-name-label-under-960-width {
        display: block;
        margin-bottom: 15px;
      }
      > .type-breed-label {
        display: block;
        margin-top: 20px;
      }
      > .type-breed-input-area {
        display: flex;
        > .type-breed-input-box {
          display: flex;
          @include validate-message();
          > .provider {
            > .patient-type-select-box {
              margin-top: 10px;
            }
            > .patient-breed-text-box {
              margin: 10px 22px 0 18px;
            }
            > .error {
              margin-left: 18px;
              &.select {
                margin: 0;
              }
            }
          }
        }
        > .delete-patient-button {
          margin-top: 10px;
        }
      }
    }
  }
}
@media (max-width: 550px) {
  .patient-data-input-form {
    > .type-breed-area-under-960-width {
      > .type-breed-input-area {
        display: block;
        > .delete-patient-button {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
